<template>
  <b-modal :id="refs" :ref="refs" no-stacking hide-footer centered :modal-class="classes">
    <div class="content" v-if="contentSlot">
      <slot name="content"></slot>
    </div>

    <slot/>

    <div class="buttons-wrapper" v-if="buttonsSlot">
      <slot name="buttons"></slot>
    </div>

  </b-modal>
</template>

<script>
  export default {
    name: "ModalDefaultTemplate",

    props: {
      refs: {
        default: '',
        type: String
      },
      contentSlot: {
        default: true,
        type: Boolean
      },
      buttonsSlot: {
        default: true,
        type: Boolean
      },
      classes: {
        default: '',
        type: String
      }
    },
  }
</script>

<style lang="scss" scoped>
 /deep/

 .modal-content {
    border-radius: 0;
    min-height: 250px;

   .modal-header {
     border-bottom: 0;

     .close {
       color: black;
       font-size: 30px;
       line-height: 33px;
       font-weight: bold;
       text-shadow: none;
       opacity: 1;
       padding: 0 15px;
       margin: 0 0 0 0;
     }
   }

   .content {
     width: 100%;
     margin-bottom: 40px;

     h3, p {
       text-align: center;
       font-size: 16px;
     }

     h3 {
       text-transform: uppercase;
     }
   }

   .buttons-wrapper {
     width: 100%;
     max-width: 300px;
     margin: auto;

     button {
       margin-bottom: 16px;
     }
   }
 }

 /deep/
 .dialog-max-width-800 {
   padding: 0 !important;

   .modal-dialog {
     max-width: 800px;
   }

   @media only screen and (min-width: 575px) {
     padding-left: 0.5rem !important;
     padding-right: 0.5rem !important;
   }
 }

 /deep/
 .dialog-max-width-1000 {
   padding: 0 !important;

   .modal-dialog {
     max-width: 1000px;
   }

   @media only screen and (min-width: 575px) {
     padding-left: 0.5rem !important;
     padding-right: 0.5rem !important;
   }
 }
</style>
