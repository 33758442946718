<template>
  <div class="ls-logo">
    <a href="https://www.loyalsolutions.eu/" role="link" target="_blank" rel="noopener">
      <div class="ls-logo-inner">
        <div class="log-item">
          Powered by
        </div>

        <div class="log-item">
          <img src="@/assets/images/lsLogo/circle-icon-black.svg" width="17" height="17" alt="Loyal Solutions Icon">
          LoyalSolutions
        </div>
      </div>
    </a>
  </div>
</template>

<script>
  export default {
    name: "LoyalSolutionsLogo",
  }
</script>

<style lang="scss" scoped>
  .ls-logo {
 /*   width: 100%;
    display: flex;
    justify-content: center;*/
    font-family: 'acid-medium-italic', "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: inline-block;

    a, a:hover, a:active, a:focus, a:visited {
      color: black;
      text-decoration: none;
    }

    .ls-logo-inner {
      display: flex;
      flex-direction: column;

      .log-item {
        &:first-child {
          font-size: 20px;
          text-align: right;
          line-height: 12px;
        }

        &:last-child {
          font-size: 30px;
          line-height: 20px;

          img {
            width: 17px;
            margin-bottom: 7px;
          }
        }
      }
    }
  }
</style>
